// src/components/Navbar.js
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Navbar as BootstrapNavbar, Nav } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe, faBars } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import 'bootstrap/dist/css/bootstrap.min.css'; // Импортируем Bootstrap
import '../css/Navbar.css';

function Navbar() {
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <div className="navbar-wrapper">
      <BootstrapNavbar expand="lg" className="navbar-container">
          <div className="container-fluid">
            <BootstrapNavbar.Toggle aria-controls="navbar-content" className="navbar-toggler">
              <FontAwesomeIcon icon={faBars} />
            </BootstrapNavbar.Toggle>
            <div className="col-12 col-lg-12 d-flex align-items-center">
              <BootstrapNavbar.Collapse id="navbar-content">
                <Nav className="custom-nav">
                  <Nav.Link as={NavLink} to="/" end className={({ isActive }) => isActive ? 'active' : ''}>{t('nav_home')}</Nav.Link>
                  <Nav.Link as={NavLink} to="/publications" end className={({ isActive }) => isActive ? 'active' : ''}>{t('nav_publications')}</Nav.Link>
                  <Nav.Link as={NavLink} to="/teaching" end className={({ isActive }) => isActive ? 'active' : ''}>{t('nav_teaching')}</Nav.Link>
                  <Nav.Link as={NavLink} to="/conferences" end className={({ isActive }) => isActive ? 'active' : ''}>{t('conferences')}</Nav.Link>
                  {/* <Nav.Link as={NavLink} to="/misc" end className={({ isActive }) => isActive ? 'active' : ''}>{t('nav_misc')}</Nav.Link> */}
                  {/* <Nav.Link as={NavLink} to="/travel" end className={({ isActive }) => isActive ? 'active' : ''}>{t('nav_travel')}</Nav.Link> */}
                  <Nav.Link className='language-switcher' onClick={() => changeLanguage(i18n.language === 'ru' ? 'en' : 'ru')}>
                    <FontAwesomeIcon icon={faGlobe} /> {i18n.language === 'ru' ? 'EN' : 'RU'}
                  </Nav.Link>
                </Nav>
              </BootstrapNavbar.Collapse>
            </div>
          </div>
      </BootstrapNavbar>
    </div>
  );
}

export default Navbar;
