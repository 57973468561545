// src/App.js
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Header from './components/Header';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Biography from './components/Biography';
import Publications from './components/Publications';
import Conferences from './components/Conferences';
// import Misc from './components/Misc';
// import Travel from './components/Travel';
import Teaching from './components/Teaching';
import ScrollToTopButton from './components/ScrollToTopButton';
import './css/App.css';

function App() {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    document.title = t('tab_title');
  }, [t, i18n.language]);
  
  return (
    <Router>
      <div className="app-container">
        <Header />
        <Navbar />
        <main className="main-content container mt-5">
          <Routes>
            <Route path="/" element={<Biography />} />
            <Route path="/publications" element={<Publications />} />
            <Route path="/conferences" element={<Conferences />} />
            {/* <Route path="/misc" element={<Misc />} /> */}
            {/* <Route path="/travel" element={<Travel />} /> */}
            <Route path="/teaching" element={<Teaching />} />
          </Routes>
        </main>
        <ScrollToTopButton />
        <Footer />
      </div>
    </Router>
  );
}

export default App;
