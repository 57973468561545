// src/components/Biography.js
import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import '../css/Biography.css';

function Biography() {
  const { t } = useTranslation();

  return (
    <Container className="biography-container">
      <Row>
        <Col lg={7}>
          <div className="biography-text-section">
            <h2>{t('biography_title')}</h2>
            <p className='biography_content'>
            {t('biography_content_part1')}
            <a href="https://msu.ru/" target="_blank" rel="noopener noreferrer">
              {t('biography_link_msu')}
            </a>
            {t('biography_content_part2')}
            <a href="https://old.mccme.ru/ium//rym/2020/winners-english.html" target="_blank" rel="noopener noreferrer">
              {t('biography_link_reward')}
            </a>
            {t('biography_content_part3')}
            </p>
          </div>
        </Col>
        <Col lg={5} className="biography-sidebar">
          <div className="biography-photo-frame">
            <Image src={`${process.env.PUBLIC_URL}/Rita.jpg`} className="biography-photo" />
          </div>
          <div className="biography-details">
            <h3>{t('name')}</h3>
            <p>{t('email')}</p>
            <h4>{t('interests_title')}</h4>
            <ul>
              {t('interests_list', { returnObjects: true }).map((interest, index) => (
                <li key={index}>{interest}</li>
              ))}
            </ul>
          </div>
        </Col>
      </Row>
      {/* Добавляем новый блок для дополнительных профилей */}
      <Row className="additional-links">
        <h3>{t('profiles_title')}</h3>
        <Col lg={6}>
          <div className="profile-section">
            <ul>
              <li><a href="https://arxiv.org/search/math?searchtype=author&query=Akhmejanova,+M" target="_blank" rel="noopener noreferrer">{t('profile_arxiv')}</a></li>
              <li><a href="https://scholar.google.com/citations?user=bp5xVjwAAAAJ&hl=en" target="_blank" rel="noopener noreferrer">{t('profile_google_scholar')}</a></li>
            </ul>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Biography;
