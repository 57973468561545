// src/components/Footer.js
import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'; 
import '../css/Footer.css';

function Footer() {

  const { t } = useTranslation();

  return (
    <footer className="footer-container">
      <Container>
        <Row>
          <Col className="footer-text text-center">
            &copy; {new Date().getFullYear()} <Link to="/" className="footer-title-link">{t('header_title')}</Link>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
