// src/components/Conferences.js
import React from 'react';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import '../css/Conferences.css';

const Conferences = () => {
  const { t } = useTranslation();

  const conferences = t('conferences_list', { returnObjects: true });
  const researchVisits = t('research_visits_list', { returnObjects: true });

  return (
    <Container>
      <section id="conferences" className="conference-section">
        <h2>{t('nav_conferences')}</h2>
        <ol>
          {Array.isArray(conferences) && conferences.map((conference, index) => (
            <li key={index}>
              {conference.title}, {conference.dates && `${conference.dates},`} ({conference.location && `${conference.location},`} {conference.year})
            </li>
          ))}
        </ol>
      </section>

      <section id="research_visits" className="conference-section">
        <h2>{t('nav_research_visits')}</h2>
        <ol>
          {Array.isArray(researchVisits) && researchVisits.map((visit, index) => (
            <li key={index}>
              {visit.institution}, {visit.month_year}, {visit.location}, {visit.professors}
            </li>
          ))}
        </ol>
      </section>
    </Container>
  );
}

export default Conferences;
