import React from 'react';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import '../css/Teaching.css';

function Teaching() {
  const { t } = useTranslation();

  return (
    <Container>

      <section id="teaching" className="teaching-section">
        <h2>{t('nav_teaching')}</h2>
        <ol>
          {t('teaching_experience', { returnObjects: true }).map((experience, index) => (
            <li key={index}>
              {experience.institution}, {experience.dates}
              {experience.location && `, ${experience.location}`}
            </li>
          ))}
        </ol>
      </section>

      <section id="work" className="work-section">
        <h2>{t('nav_work')}</h2>
        <ol>
          {t('work_experience', { returnObjects: true }).map((experience, index) => (
            <li key={index}>
              {experience.institution}, {experience.dates}
              {experience.location && `, ${experience.location}`}
            </li>
          ))}
        </ol>
      </section>
    </Container>
  );
}

export default Teaching;
