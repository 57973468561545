// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

const resources = {
  en: {
    translation: {
      "header_title": "Margarita Akhmejanova",
      "header_subtitle": "Personal site of Margarita Akhmejanova",
      "nav_home": "Home",
      "nav_publications": "Publications",
      "nav_teaching_and_work": "Teaching and work experience",
      "nav_teaching" : "Teaching",
      "nav_work": "Work Experience",
      "conferences": "Conferences",
      "nav_conferences": "Contributed talks in conferences",
      "nav_misc": "Misc",
      "nav_travel": "Travel",
      "biography_title": "Short Biography",
      "biography_content_part1": "I defended my Ph.D. in 2022 under the supervision of Dmitrii Shabanov, after graduating with honors from",
      "biography_link_msu": " Lomonosov Moscow State University",
      "biography_content_part2": " in Probability Theory and Mathematical Statistics. Originally from Almaty, Kazakhstan, and despite being an orphan from childhood and a Kazakh passport holder, I have been able to achieve academic and professional success. In 2021, I received the prestigious",
      "biography_link_reward": " 'Young Mathematicians of Russia'",
      "biography_content_part3": " award. I worked in the Laboratory of Combinatorial Mathematics and Geometry, headed by Janos Pach and Andrey Kupavskii, and I was a Postdoctoral Fellow at the KAUST AI Initiative, hosted by Francesco Orabona.",
      "positions_title": "Some of my previous positions",
      "positions_list": [
        // {
        //   "I was born on November 13, 1993, in Almaty, Kazakhstan. In 2012, I graduated from the Republican Physics and Mathematics School, where I was an active member of the math club. In 2018, I graduated with honors from Lomonosov Moscow State University, majoring in Probability Theory and Mathematical Statistics at the Faculty of Mechanics and Mathematics. My scientific advisor was Dmitrii Shabanov. In 2021, I received the prestigious 'Young Mathematicians of Russia' award from the Independent Mathematical Institute. During my postgraduate studies, I worked in the Laboratory of Combinatorial Mathematics and Geometry, which was led by Janos Pach and Andrey Kupavsky. In 2022, I defended my Ph.D. dissertation in the Department of Discrete Mathematics at the Moscow Institute of Physics and Technology, which is led by Andrey Raigorodskii, under the supervision of my advisor Dmitrii Shabanov. Currently, I am working as a postdoc at the KAUST AI Initiative hosted by Francesco Orabona.""
        //   "text": "Sept 2019 - Aug 2020: ",
        //   "bold": "Institute for Advanced Study",
        //   "remainder": ", Princeton, USA: member of the group of Computer Science and Discrete Mathematics, supervised by Prof. Avi Wigderson"
        // },
        // {
        //   "text": "Dec 2018 - Aug 2019: ",
        //   "bold": "University of Oxford",
        //   "remainder": ", England: researcher in the Combinatorics group, supervised by Prof. Peter Keevash"
        // },
        // {
        //   "text": "Nov 2017 - Nov 2018: ",
        //   "bold": "University of Birmingham",
        //   "remainder": ", England: researcher in the Combinatorics and Probability group, supervised by Prof. Daniel Kőnig and Dr. Derek Osthus"
        // },
        // {
        //   "text": "Sept 2013 - Aug 2016, Oct 2016 - Oct 2017: ",
        //   "bold": "EPFL",
        //   "remainder": ", Switzerland: researcher in the group of Discrete and Computational Geometry, supervised by Prof. Janos Pach"
        // }
      ],
      "name": "Margarita Akhmejanova",
      "email": "mechmathrita@gmail.com",
      "interests_title": "Research Interests",
      "interests_list": [
        "Probabilistic methods: random graphs, concentration inequalities, threshold phenomena, martingales, etc.",              
        "Extremal Combinatorics: Turan Problem, weak Saturation, Ramsey Theory, hypergraph coloring",
        "Selected Topics in Theoretical Computer Science"
      ],    
      "photo_alt": "Margarita Akhmejanova's Photo",
      "profiles_title": "My profiles and pages on other sites",
      "profile_researchgate": "ResearchGate",
      "profile_dblp": "DBPL",
      "profile_arxiv": "Articles on arXiv",
      "profile_google_scholar": "Profile on Google Scholar",
      "not_found_title": "Page Not Found",
      "not_found_message": "Sorry, the page you are looking for does not exist.",
      "not_found_link": "Go to Homepage",
      "tab_title": "Margarita Akhmejanova's Personal Site",
      "publications_title": "Publications",
      "publications_submitted": "Preprints",
      "publications_journal": "Journal Articles",
      "publications_conference": "Conference Papers",
      "publications_misc": "Misc",
      "journal_articles": [
          {
            "authors": "M. Akhmejanova, V. Kozhevnikov. ",
            "title": "“The maximum size of an induced forest in the binomial random graph”",
            "journal": "Discrete Mathematics 347 (7), 2024, 114030",
            "link": "https://doi.org/10.1016/j.disc.2024.114030"
          },
          {
            "authors": "Akhmejanova, M.B., Kozhevnikov, V.S. ",
            "title": "“Induced Forests and Trees in Erdős–Rényi Random Graph”",
            "journal": "Doklady. Mathematics, 2024",
            "link": "https://doi.org/10.1134/S1064562424701886"
          },
          {
            "authors": "M. Akhmejanova, K. Olmezov, A. Volostnov, I. Vorobyev, ",
            "title": "“Wiener index and graphs, almost half of whose vertices satisfy Soltes property”",
            "journal": "Discrete Applied Mathematics, 2023",
            "link": "https://doi.org/10.1016/j.dam.2022.09.021"
          },
          {
            "authors": "M. Akhmejanova, M. Zhukovskii, ",
            "title": "“EMSO (FO^2) 0-1 law fails for all dense random graphs”",
            "journal": "SIAM Journal on Discrete Mathematics, 2022",
            "link": "https://doi.org/10.1137/21M1429655"
          },
          {
            "authors": "M. Akhmejanova, J. Balogh, D. Shabanov, ",
            "title": "“Chain method for panchromatic colorings of hypergraphs”",
            "journal": "Discrete Applied Mathematics, 321, 2022, 72-81",
            "link": "https://doi.org/10.1016/j.dam.2022.06.005"
          },
          {
            "authors": "M. Akhmejanova, D. Shabanov, ",
            "title": "“Equitable colorings of hypergraphs with r colors”",
            "journal": "Journal of Mathematical Sciences, 2022",
            "link": "https://doi.org/10.1007/s10958-022-05823-x"
          },
          {
            "authors": "M. Akhmejanova, ",
            "title": "“Bi-uniform hypergraph”",
            "journal": "Trudy MIPT, 13:51, 2021",
            "link": "https://doi.org/10.53815/20726759_2021_13_3_23"
          },
          {
            "authors": "M. Akhmejanova, D. Shabanov, ",
            "title": "“Coloring hypergraphs with bounded cardinalities of edge intersections”",
            "journal": "Discrete Mathematics, 343, 2020, 111692",
            "link": "https://doi.org/10.1016/j.disc.2019.111692"
          },
          {
            "authors": "M. Akhmejanova, D. Shabanov, ",
            "title": "“Equitable colorings of hypergraphs with few edges”",
            "journal": "Discrete Applied Mathematics, 276, 2020, 212",
            "link": "https://doi.org/10.1016/j.dam.2019.03.024"
          },
          {
            "authors": "M. Akhmejanova, ",
            "title": "“On Equitable Colorings of Hypergraphs”",
            "journal": "Mathematical Notes, 106, 2019, 319326",
            "link": "https://doi.org/10.1134/S0001434619090013"
          },
          {
            "authors": "M. Akhmejanova, D. Shabanov, ",
            "title": "“Colorings of b-simple hypergraphs”",
            "journal": "Electron. Notes Discrete Math, 61, 2017, 2935",
            "link": "https://doi.org/10.1016/j.endm.2017.06.017"
          }        
      ],
      "submitted_articles": [
        {
          "authors": "G. Sokolov, M. Thiessen, M. Akhmejanova, F. Vitale, F. Orabona, ",
          "title": "“Self-Directed Learning of Convex Labelings on Graphs”",
          "journal": "arXiv.2409.01428",
          "link": "https://arxiv.org/abs/2409.01428"
        },
        {
          "authors": "M. Akhmejanova, A. Kuchukova, A. Valyuzhenich, I. Vorobyev, ",
          "title": "“Bicrucial k-power-free permutations”",
          "journal": "arXiv.2409.01206",
          "link": "https://arxiv.org/abs/2409.01206"
        },
        {
          "authors": "M. Akhmejanova, V. Kozhevnikov, M. Zhukovskii, ",
          "title": "“Maximum induced trees and forests of bounded degree in G(n, p)”",
          "journal": "arXiv.2408.15215",
          "link": "https://arxiv.org/abs/2408.15215"
        },
        {
          "authors": "M. Akhmejanova, I. Vorobyev, M. Zhukovskii, ",
          "title": "“Weak saturation number of bipartite complete graphs (K_{s,t}) in the clique (K_n) when (s+t) is almost n”",
          "journal": "",
          "link": "https://easychair.org/cfp/ICGNC1"
        },
       {"authors": "M. Akhmejanova, I. Bogdanov, G. Chelnokov, ",
        "title": "“The continualization approach to on-line hypergraph coloring”",
        "journal": "arXiv.2211.09486",
        "link": "https://arxiv.org/abs/2211.09486"
       }
      ],
      "conferences_list": [
        {
          "title": "The 23rd Thailand-Japan Conference on Discrete and Computational Geometry, Graphs, and Games",
          "year": 2021
        },
        {
          "title": "RSA",
          "dates": "15-19 July",
          "location": "Zurich, Switzerland",
          "year": 2019
        },
        {
          "title": "RSA",
          "dates": "7-11 August",
          "location": "Gniezno, Poland",
          "year": 2017
        },
        {
          "title": "EUROCOMB",
          "dates": "28 August - 1 September",
          "location": "Vienna, Austria",
          "year": 2017
        },
        {
          "title": "2nd Russian-Hungarian Workshop",
          "dates": "June 27-29",
          "location": "Budapest, Hungary",
          "year": 2018
        },
        {
          "title": "Lomonosov-2017",
          "location": "Russia, Moscow",
          "year": 2017
        },
        {
          "title": "1st Russian-Hungarian Workshop on Discrete Mathematics",
          "location": "Russia, Moscow",
          "year": 2017
        },
        {
          "title": "Lomonosov-2018",
          "location": "Russia, Moscow",
          "year": 2018
        },
        {
          "title": "61st All-Russian Scientific Conference of MIPT",
          "location": "Russia, Dolgoprudny",
          "year": 2018
        },
        {
          "title": "Extreme Combinatorics and Discrete Geometry",
          "location": "Russia, Maikop",
          "year": 2018
        },
        {
          "title": "International Conference on Graphs, Networks and Combinatorics",
          "location": "New Delhi, India",
          "year": 2023
        },
        {
          "title": "CanaDam 2023",
          "note": "My extended abstract was reviewed and approved but since I did not get Canada visa in time I did not deliver it.",
          "year": 2023
        },
        {
          "title": "RSA 2023",
          "location": "Pittsburgh, USA",
          "year": 2023
        }
      ],
      "nav_research_visits": "Research Visits",
      "research_visits_list": [
        {
          "institution": "IMPA Institute",
          "month_year": "December 2021",
          "location": "Rio-de-Janeiro, Brazil",
          "professors": "visiting Prof. Robert Morris and Taísa Martins"
        },
        {
          "institution": "Alfréd Rényi Institute of Mathematics",
          "month_year": "August 2021",
          "location": "Budapest, Hungary",
          "professors": "visiting Prof. András Gyárfás and János Pach"
        },
        {
          "institution": "Sobolev Institute of Mathematics",
          "month_year": "February 2019",
          "location": "Novosibirsk, Russia",
          "professors": "visiting Prof. V. Potapov and Ann Taranenko"
        }
      ],
      "work_experience": [
          {
            "institution": "King Abdullah University of Science and Technology, Saudi Arabia",
            "dates": "August 2021-present"
          },
          {
            "institution": "Laboratory of Combinatorial and Geometric Structures, Junior Research",
            "location": "https://combgeo.org/en/",
            "dates": "January 2020-August 2021"
          },
          {
            "institution": "Laboratory for Advanced Combinatorics and Network Applications, MIPT, Engineer",
            "dates": "January 2020-August 2021"
          },
          {
            "institution": "Department of Discrete Mathematics, MIPT, Teacher, Probability and Theory of measure",
            "dates": "January 2018-Autumn 2019"
          },
          {
            "institution": "Lomonosov Moscow State University, Assistant, Combinatorics",
            "dates": "January 2018-Autumn 2019"
          }
      ],
      "teaching_experience": [
        {
          "institution": "Department of Discrete Mathematics, MIPT, Teacher, Probability and Theory of measure",
          "dates": "January 2018-Autumn 2019"
        },
        {
          "institution": "Lomonosov Moscow State University, Assistant, Combinatorics",
          "dates": "January 2018-Autumn 2019"
        }
      ],   
    }
  },
  ru: {
    translation: {
      "header_title": "Ахмеджанова Маргарита",
      "header_subtitle": "Персональный сайт Ахмеджановой Маргариты",
      "nav_home": "Главная",
      "nav_publications": "Публикации",
      "nav_teaching_and_work": "Преподавание и опыт работы",
      "nav_teaching": "Преподавание",
      "nav_work": "Опыт работы",
      "nav_conferences": "Доклады на конференциях",
      "nav_misc": "Разное",
      "nav_travel": "Поездки",
      "biography_title": "Краткая Биография",
      "biography_content_part1":"Я родилась 13 ноября 1993 года в Алматы, Казахстан. В 2012 году окончила Республиканскую физико-математическую школу, где активно участвовала в математическом кружке. В 2018 году с отличием окончила механико-математический факультет",
      "biography_link_msu": " МГУ имени Ломоносова",
      "biography_content_part2": " кафедру теории вероятностей и математической статистики, под руководством Дмитрия Шабанова. В 2021 году получила престижную награду",
      "biography_link_text": " 'Молодые математики России'",
      "biography_content_part3":  " от Независимого математического института. Во время аспирантуры работала в Лаборатории комбинаторной математики и геометрии, которой руководили Яноша Паха и Андрея Купавского. В 2022 году защитила кандидатскую диссертацию на кафедре дискретной математики Московского физико-технического института, которой руководит Андрей Райгородский, под руководством своего научного руководителя Дмитрия Шабанова. В настоящее время работаю постдоком в KAUST AI Initiative под руководством Франческо Орабоны.",
      "positions_title": "Некоторые из моих позиций в прошлом",
      "positions_list": [
        // {
        //   "text": "Сент 2019 - Авг 2020: ",
        //   "bold": "Институт Перспективных Исследований",
        //   "remainder": ", Принстон, США: член группы Компьютерных Наук и Дискретной Математики, под руководством проф. Ави Вигдерсона"
        // },
        // {
        //   "text": "Дек 2018 - Авг 2019: ",
        //   "bold": "Университет Оксфорда",
        //   "remainder": ", Англия: исследователь (постдок) в группе Комбинаторики, под руководством проф. Питера Киваша"
        // },
        // {
        //   "text": "Ноя 2017 - Ноя 2018: ",
        //   "bold": "Университет Бирмингема",
        //   "remainder": ", Англия: исследователь (постдок) в группе Комбинаторики и Вероятностей, под руководством проф. Даниэла Кён и Дерика Остуса"
        // },
        // {
        //   "text": "Сент 2013 - Авг 2016, Окт 2016 - Окт 2017: ",
        //   "bold": "EPFL",
        //   "remainder": ", Швейцария: постдок в группе Дискретной и Вычислительной Геометрии, под руководством проф. Яноша Паха"
        // }
      ],
      "name": "Маргарита Ахмеджанова",
      "email": "mechmathrita@gmail.com",
      "interests_title": "Научные интересы",
      "interests_list": [
        "Вероятностные методы: случайные графы, концентрационные неравенства, пороговая вероятность, мартингалы и т.д.",
        "Экстремальная комбинаторика: задача Турана, слабая насыщенность, теория Рамсея, раскраска гиперграфов",
        "Отдельные разделы Теоретической Информатики"
       ],
      "photo_alt": "Фото Маргариты Ахмеджановой",
      "profiles_title": "Мои профили и страницы на других сайтах",
      "profile_researchgate": "ResearchGate",
      "profile_dblp": "DBPL",
      "profile_arxiv": "Статьи на arXiv",
      "profile_google_scholar": "Профиль на Google Scholar",
      "not_found_title": "Страница не найдена",
      "not_found_message": "Извините, страница, которую вы ищете, не существует.",
      "not_found_link": "Перейти на главную",
      "tab_title": "Персональный сайт Маргариты Ахмеджановой",
      "publications_title": "Публикации",
      "publications_submitted": "Препринты",
      "publications_journal": "Статьи в журналах",
      "publications_conference": "Материалы конференций",
      "conferences": "Конференции",
      "publications_misc": "Разное",
      "journal_articles": [
        {
          "authors": "M. Akhmejanova, V. Kozhevnikov. ",
          "title": "“The maximum size of an induced forest in the binomial random graph”",
          "journal": "Discrete Mathematics 347 (7), 2024, 114030",
          "link": "https://doi.org/10.1016/j.disc.2024.114030"
        },
        {
          "authors": "Akhmejanova, M.B., Kozhevnikov, V.S. ",
          "title": "“Induced Forests and Trees in Erdős–Rényi Random Graph”",
          "journal": "Doklady. Mathematics, 2024",
          "link": "https://doi.org/10.1134/S1064562424701886"
        },
        {
          "authors": "M. Akhmejanova, K. Olmezov, A. Volostnov, I. Vorobyev, ",
          "title": "“Wiener index and graphs, almost half of whose vertices satisfy Soltes property”",
          "journal": "Discrete Applied Mathematics, 2023",
          "link": "https://doi.org/10.1016/j.dam.2022.09.021"
        },
        {
          "authors": "M. Akhmejanova, M. Zhukovskii, ",
          "title": "“EMSO (FO^2) 0-1 law fails for all dense random graphs”",
          "journal": "SIAM Journal on Discrete Mathematics, 2022",
          "link": "https://doi.org/10.1137/21M1429655"
        },
        {
          "authors": "M. Akhmejanova, J. Balogh, D. Shabanov, ",
          "title": "“Chain method for panchromatic colorings of hypergraphs”",
          "journal": "Discrete Applied Mathematics, 321, 2022, 72-81",
          "link": "https://doi.org/10.1016/j.dam.2022.06.005"
        },
        {
          "authors": "M. Akhmejanova, D. Shabanov, ",
          "title": "“Equitable colorings of hypergraphs with r colors”",
          "journal": "Journal of Mathematical Sciences, 2022",
          "link": "https://doi.org/10.1007/s10958-022-05823-x"
        },
        {
          "authors": "M. Akhmejanova, ",
          "title": "“Bi-uniform hypergraph”",
          "journal": "Trudy MIPT, 13:51, 2021",
          "link": "https://doi.org/10.53815/20726759_2021_13_3_23"
        },
        {
          "authors": "M. Akhmejanova, D. Shabanov, ",
          "title": "“Coloring hypergraphs with bounded cardinalities of edge intersections”",
          "journal": "Discrete Mathematics, 343, 2020, 111692",
          "link": "https://doi.org/10.1016/j.disc.2019.111692"
        },
        {
          "authors": "M. Akhmejanova, D. Shabanov, ",
          "title": "“Equitable colorings of hypergraphs with few edges”",
          "journal": "Discrete Applied Mathematics, 276, 2020, 212",
          "link": "https://doi.org/10.1016/j.dam.2019.03.024"
        },
        {
          "authors": "M. Akhmejanova, ",
          "title": "“On Equitable Colorings of Hypergraphs”",
          "journal": "Mathematical Notes, 106, 2019, 319326",
          "link": "https://doi.org/10.1134/S0001434619090013"
        },
        {
          "authors": "M. Akhmejanova, D. Shabanov, ",
          "title": "“Colorings of b-simple hypergraphs”",
          "journal": "Electron. Notes Discrete Math, 61, 2017, 2935",
          "link": "https://doi.org/10.1016/j.endm.2017.06.017"
        }
      ],
      "submitted_articles": [
        {
          "authors": "G. Sokolov, M. Thiessen, M. Akhmejanova, F. Vitale, F. Orabona, ",
          "title": "“Self-Directed Learning of Convex Labelings on Graphs”",
          "journal": "arXiv.2409.01428",
          "link": "https://arxiv.org/abs/2409.01428"
        },
        {
          "authors": "M. Akhmejanova, A. Kuchukova, A. Valyuzhenich, I. Vorobyev, ",
          "title": "“Bicrucial k-power-free permutations”",
          "journal": "arXiv.2409.01206",
          "link": "https://arxiv.org/abs/2409.01206"
        },
        {
          "authors": "M. Akhmejanova, V. Kozhevnikov, M. Zhukovskii, ",
          "title": "“Maximum induced trees and forests of bounded degree in G(n, p)”",
          "journal": "arXiv.2408.15215",
          "link": "https://arxiv.org/abs/2408.15215"
        },
        {
          "authors": "M. Akhmejanova, I. Vorobyev, M. Zhukovskii, ",
          "title": "“Weak saturation number of bipartite complete graphs (K_{s,t}) in the clique (K_n) when (s+t) is almost n”",
          "journal": "",
          "link": "https://easychair.org/cfp/ICGNC1"
        },
        { "authors": "M. Akhmejanova, I. Bogdanov, G. Chelnokov, ",
        "title": "“The continualization approach to on-line hypergraph coloring”",
        "journal": "arXiv.2211.09486",
        "link": "https://arxiv.org/abs/2211.09486"
        }
      ],
      "conferences_list": [
        {
          "title": "The 23rd Thailand-Japan Conference on Discrete and Computational Geometry, Graphs, and Games",
          "year": 2021
        },
        {
          "title": "RSA",
          "dates": "15-19 July",
          "location": "Zurich, Switzerland",
          "year": 2019
        },
        {
          "title": "RSA",
          "dates": "7-11 August",
          "location": "Gniezno, Poland",
          "year": 2017
        },
        {
          "title": "EUROCOMB",
          "dates": "28 August - 1 September",
          "location": "Vienna, Austria",
          "year": 2017
        },
        {
          "title": "2nd Russian-Hungarian Workshop",
          "dates": "June 27-29",
          "location": "Budapest, Hungary",
          "year": 2018
        },
        {
          "title": "Lomonosov-2017",
          "location": "Russia, Moscow",
          "year": 2017
        },
        {
          "title": "1st Russian-Hungarian Workshop on Discrete Mathematics",
          "location": "Russia, Moscow",
          "year": 2017
        },
        {
          "title": "Lomonosov-2018",
          "location": "Russia, Moscow",
          "year": 2018
        },
        {
          "title": "61st All-Russian Scientific Conference of MIPT",
          "location": "Russia, Dolgoprudny",
          "year": 2018
        },
        {
          "title": "Extreme Combinatorics and Discrete Geometry",
          "location": "Russia, Maikop",
          "year": 2018
        },
        {
          "title": "International Conference on Graphs, Networks and Combinatorics",
          "location": "New Delhi, India",
          "year": 2023
        },
        {
          "title": "CanaDam 2023",
          "note": "My extended abstract was reviewed and approved but since I did not get Canada visa in time I did not deliver it.",
          "year": 2023
        },
        {
          "title": "RSA 2023",
          "location": "Pittsburgh, USA",
          "year": 2023
        }
      ],
      "nav_research_visits": "Научные визиты",
      "research_visits_list": [
        {
          "institution": "IMPA Institute",
          "month_year": "December 2021",
          "location": "Rio-de-Janeiro, Brazil",
          "professors": "visiting Prof. Robert Morris and Taísa Martins"
        },
        {
          "institution": "Alfréd Rényi Institute of Mathematics",
          "month_year": "August 2021",
          "location": "Budapest, Hungary",
          "professors": "visiting Prof. András Gyárfás and János Pach"
        },
        {
          "institution": "Sobolev Institute of Mathematics",
          "month_year": "February 2019",
          "location": "Novosibirsk, Russia",
          "professors": "visiting Prof. V. Potapov and Ann Taranenko"
        }
      ],
      "work_experience": [
        {
          "institution": "King Abdullah University of Science and Technology, Saudi Arabia",
          "dates": "August 2021-present"
        },
        {
          "institution": "Laboratory of Combinatorial and Geometric Structures, Junior Research",
          "location": "https://combgeo.org/en/",
          "dates": "January 2020-August 2021"
        },
        {
          "institution": "Laboratory for Advanced Combinatorics and Network Applications, MIPT, Engineer",
          "dates": "January 2020-August 2021"
        },
        {
          "institution": "Department of Discrete Mathematics, MIPT, Teacher, Probability and Theory of measure",
          "dates": "January 2018-Autumn 2019"
        },
        {
          "institution": "Lomonosov Moscow State University, Assistant, Combinatorics",
          "dates": "January 2018-Autumn 2019"
        }
      ],
      "teaching_experience": [
        {
          "institution": "Department of Discrete Mathematics, MIPT, Teacher, Probability and Theory of measure",
          "dates": "January 2018-Autumn 2019"
        },
        {
          "institution": "Lomonosov Moscow State University, Assistant, Combinatorics",
          "dates": "January 2018-Autumn 2019"
        }
      ],       
    }
  }
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: "en", // default language
    fallbackLng: "en",
    interpolation: {
      escapeValue: false // Это позволяет встраивать HTML
    }
  });

export default i18n;
