// src/components/Header.js
import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import 'bootstrap/dist/css/bootstrap.min.css'; // Импортируем Bootstrap
import '../css/Header.css';

function Header() {
  const { t } = useTranslation();

  return (
    <header className="header-container">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col text-center">
            <h1 className="header-title">
              <Link to="/" className="header-title-link">{t('header_title')}</Link>
            </h1>
            <p className="header-subtitle">{t('header_subtitle')}</p>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
